import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://api.texstage.ru/api/v1',
	timeout: 1200000, 
	transformRequest: [function (data, headers) { 
		//headers['Authorization'] = 'Bearer '+localStorage.getItem('token') 
		return data;
	}], 
});
 
instance.interceptors.request.use(async config => {
	return config
})
   
instance.interceptors.response.use(async response => { 
	return response
})

export default instance;