import server from '@/api/http_texstage';

export async function getFilter(params){  
	let { data } = await server.get('warehouse_sale/entry/filter/get', { params });
	return data;
}   
export async function setFilter(params){  
	let { data } = await server.get('warehouse_sale/entry/filter/set', { params });
	return data;
}    
export async function getEntryAll(params){  
	let { data } = await server.get('warehouse_sale/entry/all/get', { params });
	return data;
}    
export async function getHeadEntry(params){  
	let { data } = await server.get('warehouse_sale/entry/head/get', { params });
	return data;
} 
export async function getEntryProducts(params){  
	let { data } = await server.get('warehouse_sale/entry/products/get', { params });
	return data;
}   
export async function getModify(params){  
	let { data } = await server.get('warehouse_sale/entry/modify', { params });
	return data;
}    
export async function upEntry(params){  
	let { data } = await server.get('warehouse_sale/entry/up', { params });
	return data;
} 
export async function delEntry(params){  
	let { data } = await server.get('warehouse_sale/entry/delete', { params });
	return data;
}    