import server from '@/api/http_texstage';
 
export async function getWarehouseSaleList(params){  
	let { data } = await server.get('warehouse_sale/warehouse/list/get', { params });
	return data;
}   
export async function upWarehouseSaleList(params){  
	let { data } = await server.get('warehouse_sale/warehouse/list/up', { params });
	return data;
}   
export async function deleteWarehouseSaleList(params){  
	let { data } = await server.get('warehouse_sale/warehouse/list/delete', { params });
	return data;
}   