import { createWebHistory, createRouter } from "vue-router";
import store from '../store'
import middlewarePipeline from './middlewarePipeline'
import token from './middleware/token' 
import guest from './middleware/guest' 
import auth from './middleware/auth'  
import rights_verification from './middleware/rights_verification'    
 
const routes = [
  {
    path: "/",
    name: "page-index",
    component: () => import('@/views/pages/page_index.vue'),
    meta: {  
      layout: 'auth',
      middleware: [
        token, rights_verification  
      ]  
    }, 
  },  
  {
    name: 'analytics-index',
    path: '/analytics',
    component: () => import('@/views/analytics/analytics_index.vue'), 
    children:[
      {
        name: 'analytics-callcenter',
        path: 'callcenter',
        component: () => import('@/views/analytics/analytics_callcenter.vue'), 
      },    
      {
        name: 'analytics-base',
        path: 'base',
        component: () => import('@/views/analytics/analytics_base.vue'), 
      },  
      {
        name: 'account-optional',
        path: 'optional',
        component: () => import('@/views/analytics/analytics_optional.vue'), 
      },
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
  {
    name: 'current-status-index',
    path: '/current_status',
    component: () => import('@/views/current_status/current_status_index.vue'), 
    children:[
      {
        name: 'current-status-callcenter',
        path: 'callcenter',
        component: () => import('@/views/current_status/current_status_callcenter.vue'), 
      },    
      {
        name: 'current-status-base',
        path: 'base',
        component: () => import('@/views/current_status/current_status_base.vue'), 
      },   
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  }, 
  {
    name: 'result-period-index',
    path: '/result_period',
    component: () => import('@/views/result_period/result_period_index.vue'), 
    children:[
      {
        name: 'result-period-callcenter',
        path: 'callcenter',
        component: () => import('@/views/result_period/result_period_callcenter.vue'), 
      },    
      {
        name: 'result-period-base',
        path: 'base',
        component: () => import('@/views/result_period/result_period_base.vue'), 
      },   
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
  {
    name: 'expenses-index',
    path: '/expenses',
    component: () => import('@/views/expenses/expenses_index.vue'), 
    children:[
      {
        name: 'expenses-result-period',
        path: 'result_period',
        component: () => import('@/views/expenses/expenses_result_period.vue'), 
      },    
      {
        name: 'expenses-accounting',
        path: 'accounting',
        component: () => import('@/views/expenses/expenses_accounting.vue'), 
      },
      {
        name: 'expenses-budget',
        path: 'budget',
        component: () => import('@/views/expenses/expenses_budget.vue'), 
      }, 
      {
        name: 'expenses-input-data',
        path: 'input_data',
        component: () => import('@/views/expenses/expenses_input_data.vue'), 
      },  
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
 
  {
    name: 'fot-index',
    path: '/fot',
    component: () => import('@/views/fot/fot_index.vue'), 
    children:[
      {
        name: 'fot-result',
        path: 'result',
        component: () => import('@/views/fot/fot_result.vue'), 
      },    
      {
        name: 'fot-statement',
        path: 'statement',
        component: () => import('@/views/fot/fot_statement.vue'), 
      }, 
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
  {
    name: 'ebitda-index',
    path: '/ebitda',
    component: () => import('@/views/ebitda/ebitda_index.vue'), 
    children:[
      {
        name: 'ebitda-project',
        path: 'project',
        component: () => import('@/views/ebitda/ebitda_project.vue'), 
      },    
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
  {
    name: 'sales-pf-index',
    path: '/sales_plan_fact',
    component: () => import('@/views/sales_plan_fact/sales_pf_index.vue'), 
    children:[
      {
        name: 'sales-pf-current',
        path: 'current',
        component: () => import('@/views/sales_plan_fact/sales_pf_current.vue'), 
      }, 
      {
        name: 'sales-pf-plan',
        path: 'plan',
        component: () => import('@/views/sales_plan_fact/sales_pf_plan.vue'), 
      }, 
      {
        name: 'sales-pf-input',
        path: 'input',
        component: () => import('@/views/sales_plan_fact/sales_pf_input.vue'), 
      },  
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },
  {
    name: 'warehouse-index',
    path: '/warehouse',
    component: () => import('@/views/warehouse/warehouse_index.vue'), 
    children:[
      {
        name: 'warehouse-entry',
        path: 'entry',
        component: () => import('@/views/warehouse/warehouse_entry.vue'), 
      }, 
      {
        name: 'warehouse-remains',
        path: 'remains',
        component: () => import('@/views/warehouse/warehouse_remains.vue'), 
      }, 
      {
        name: 'warehouse-orders',
        path: 'orders',
        component: () => import('@/views/warehouse/warehouse_orders.vue'), 
      },
      {
        name: 'warehouse-workshop',
        path: 'workshop',
        component: () => import('@/views/warehouse/warehouse_workshop.vue'), 
      },  
      {
        name: 'warehouse-write-offs',
        path: 'write_offs',
        component: () => import('@/views/warehouse/warehouse_write_offs.vue'), 
      }, 
      {
        name: 'warehouse-reports',
        path: 'reports',
        component: () => import('@/views/warehouse/warehouse_reports.vue'), 
      }, 
      {
        name: 'warehouse-input-data',
        path: 'input-data',
        component: () => import('@/views/warehouse/warehouse_input_data.vue'), 
      },    
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },


  {
    name: 'warehouse-sale-index',
    path: '/warehouse_sale',
    component: () => import('@/views/warehouse_sale/warehouse_index.vue'), 
    children:[
      {
        name: 'warehouse-sale-list',
        path: 'list',
        component: () => import('@/views/warehouse_sale/warehouse_list.vue'), 
      }, 
      {
        name: 'warehouse-sale-entry',
        path: 'entry',
        component: () => import('@/views/warehouse_sale/warehouse_entry.vue'), 
      }, 
      {
        name: 'warehouse-sale-remains',
        path: 'remains',
        component: () => import('@/views/warehouse_sale/warehouse_remains.vue'), 
      }, 
      {
        name: 'warehouse-sale-orders',
        path: 'orders',
        component: () => import('@/views/warehouse_sale/warehouse_orders.vue'), 
      }, 
      {
        name: 'warehouse-sale-write-offs',
        path: 'write_offs',
        component: () => import('@/views/warehouse_sale/warehouse_write_offs.vue'), 
      }, 
      {
        name: 'warehouse-sale-reports',
        path: 'reports',
        component: () => import('@/views/warehouse_sale/warehouse_reports.vue'), 
      }, 
      {
        name: 'warehouse-sale-input-data',
        path: 'input-data',
        component: () => import('@/views/warehouse_sale/warehouse_input_data.vue'), 
      },    
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }
  },

  {
    name: 'marketing-email-index',
    path: '/marketing_email',
    component: () => import('@/views/marketing/marketing_email_index.vue'), 
    children:[
      {
        name: 'marketing-email-surveys',
        path: 'surveys',
        component: () => import('@/views/marketing/marketing_email_surveys.vue'), 
      },
      {
        name: 'marketing-email-analytics',
        path: 'analytics',
        component: () => import('@/views/marketing/marketing_email_analytics.vue'), 
      },
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    } 
  },

  {
    name: 'marketing-track-analytic-index',
    path: '/track_analytic',
    component: () => import('@/views/marketing/marketing_track_analytic_index.vue'), 
    children:[
      {
        name: 'marketing-track-analytic-report',
        path: 'report',
        component: () => import('@/views/marketing/marketing_track_analytic_report.vue'), 
      },
      {
        name: 'marketing-track-analytic-utm',
        path: 'utm',
        component: () => import('@/views/marketing/marketing_track_analytic_utm.vue'), 
      },
      {
        name: 'marketing-track-analytic-costs',
        path: 'costs',
        component: () => import('@/views/marketing/marketing_track_analytic_costs.vue'), 
      },
      {
        name: 'marketing-track-analytic-short-link',
        path: 'short_link',
        component: () => import('@/views/marketing/marketing_track_analytic_short_link.vue'), 
      },
      {
        name: 'marketing-track-analytic-settings',
        path: 'settings',
        component: () => import('@/views/marketing/marketing_track_analytic_settings.vue'), 
      },
      {
        name: 'marketing-track-analytic-yandex-app',
        path: 'yandex_app',
        component: () => import('@/views/marketing/marketing_track_analytic_yandex_app.vue'), 
      },
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    } 
  },

  {
    name: 'marketing-publications-networks-index',
    path: '/publications_networks',
    component: () => import('@/views/marketing/publications_networks_index.vue'), 
    children:[
      {
        name: 'marketing-publications-networks-create',
        path: 'create',
        component: () => import('@/views/marketing/publications_networks_create.vue'), 
      },
      {
        name: 'marketing-publications-networks-delay',
        path: 'delay',
        component: () => import('@/views/marketing/publications_networks_delay.vue'), 
      },
      {
        name: 'marketing-publications-networks-published',
        path: 'published',
        component: () => import('@/views/marketing/publications_networks_published.vue'), 
      },
      {
        name: 'marketing-publications-networks-settings',
        path: 'settings',
        component: () => import('@/views/marketing/publications_networks_settings.vue'), 
      },
      {
        name: 'marketing-publications-networks-vkauth',
        path: 'vk_auth',
        component: () => import('@/views/marketing/publications_networks_vk_auth.vue'), 
      }, 
      {
        name: 'marketing-publications-networks-okauth',
        path: 'ok_auth',
        component: () => import('@/views/marketing/publications_networks_ok_auth.vue'), 
      }, 
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    } 
  },
 
  {
    name: 'admin-index',
    path: '/admin',
    component: () => import('@/views/admin/admin_index.vue'), 
    children:[
      {
        name: 'admin-users',
        path: 'users',
        component: () => import('@/views/admin/admin_users.vue'), 
      }, 
      {
        name: 'admin-managers',
        path: 'managers',
        component: () => import('@/views/admin/admin_managers.vue'), 
      }, 
      {
        name: 'admin-managers-plan',
        path: 'managers_plan',
        component: () => import('@/views/admin/admin_managers_plan.vue'), 
      },
      {
        name: 'admin-managers-kpi',
        path: 'managers_kpi',
        component: () => import('@/views/admin/admin_managers_kpi.vue'), 
      },     
    ],
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth
      ]  
    }
  }, 

  { 
    path: '/access_closed', 
    name: "access-closed",
    component: () => import('@/views/access_closed.vue'),
    meta: {  
      layout: 'account', 
      middleware: [
        token, auth, rights_verification
      ]  
    }, 
  },  

  { 
    path: '/survey/:id', 
    name: "page-survey",
    component: () => import('@/views/pages/page_survey.vue'),
    meta: {  
      layout: 'page', 
    }, 
  }, 

  { 
    path: '/:pathMatch(.*)*', 
    name: "404",
    component: () => import('@/views/pages/page_404.vue'),
    meta: {  
      layout: 'page', 
      // middleware: [
      //   token, guest, auth
      // ]  
    }, 
  },    
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  store.dispatch("getRoute/setRoute", { from, to });

  if(!to.meta.middleware) return next()

  let params = to.params
  const middleware = to.meta.middleware
  const context = {
      to,
      from,
      next,
      store,
      params
  }
  return middleware[0]({
      ...context, 
      nextMiddleware: middlewarePipeline(context, middleware, 1)
  })

});

export default router;