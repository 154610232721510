import * as api from '@/api/warehouse_sale_input_data.js';

export default {
	namespaced: true,
	state: {
		filter:{
			warehouse_id:null,
		},
		category_product:[],
		colour_product:[],
		names_product:[],
		supplier_product:[],
		article_supplier_product:[],
		payer_product:[],
		units_product:[],
		category_write_offs:[],
		zones:[],
		articles:[],
		min_remain:[],
		articles_list:[],
		category_product_load_status:false,
		colour_product_load_status:false,
		supplier_product_load_status:false,
		article_supplier_product_load_status:false,
		payer_product_load_status:false,
		names_product_load_status:false,
		units_product_load_status:false,
		category_write_offs_load_status:false,
		articles_load_status:false,
		zones_load_status:false,
		min_remain_load_status:false, 
		copy:{
			warehouse:{
				id:null,
				name:null
			},
			obj:null,
			notify:{
				status:false,
				text:null
			}
		}, 
		delete_obj:{
			status:false,
			id:null,
			name:null,
			obj_name:null,
			message:null
		},
		type_class:{
			status:false,
			obj_name:null,
			category_key:null, 
		}, 
		articles_page:1,
		articles_row:20,
		articles_quantity_page:0
	},
	getters: {
		warehouse_sale_input_data:state=>{
			return state
		},  
	},
	mutations: {  
		setFilter(state, filter){ 
			state.filter.warehouse_id = filter.warehouse_id; 
		},  
		setFilterWarehouse(state, id){ 
			state.filter.warehouse_id = id; 
		},   
		setLoadStatus (state, data){ 
			if(data.type == "category_product")
				state.category_product_load_status = data.status 
			if(data.type == "colour_product")
				state.colour_product_load_status = data.status 
			if(data.type == "names_product")
				state.names_product_load_status = data.status 
			if(data.type == "supplier_product")
				state.supplier_product_load_status = data.status 
			if(data.type == "article_supplier_product")
				state.article_supplier_product_load_status = data.status 			 
			if(data.type == "payer_product")
				state.payer_product_load_status = data.status 
			if(data.type == "units_product")
				state.units_product_load_status = data.status 
			if(data.type == "category_write_offs")
				state.category_write_offs_load_status = data.status
			if(data.type == "zones")
				state.zones_load_status = data.status 
			if(data.type == "articles")
				state.articles_load_status = data.status 
			if(data.type == "min_remain")
				state.min_remain_load_status = data.status 
		},    
		setCategoryProduct(state, category_product){ 
			state.category_product = category_product; 
		}, 
		setColorProduct(state, colour_product){ 
			state.colour_product = colour_product; 
		},  
		setNamesProduct(state, names_product){ 
			state.names_product = names_product; 
		},  
		setSupplierProduct(state, supplier_product){ 
			state.supplier_product = supplier_product; 
		},  
		setArticleSupplierProduct(state, article_supplier_product){ 
			state.article_supplier_product = article_supplier_product; 
		},  
		setPayerProduct(state, payer_product){ 
			state.payer_product = payer_product; 
		},  
		setUnitsProduct(state, units_product){ 
			state.units_product = units_product; 
		},  
		setCategoryWriteOffs(state, category_write_offs){ 
			state.category_write_offs = category_write_offs; 
		},
		setZones(state, zones){ 
			state.zones = zones; 
		}, 
		setArticles(state, articles){ 
			state.articles = articles; 
		}, 
		setMinRemain(state, min_remain){ 
			state.min_remain = min_remain; 
		},
		setArticlesList(state, articles_list){ 
			state.articles_list = articles_list; 
		},   
		setDeleteObj(state, data){ 
			state.delete_obj.status = data.status; 
			state.delete_obj.id = data.id; 
			state.delete_obj.name = data.name; 
			state.delete_obj.obj_name = data.obj_name; 
			state.delete_obj.message = data.message; 
		},  
		showTypeClass(state, data){ 
			console.log(data);
			state.type_class.status = data.status; 
			state.type_class.obj_name = data.obj_name; 
			state.type_class.category_key = data.key;  
		},  
		setArticlesRow(state, articles_row){ 
			state.articles_row = articles_row; 
		},
		setArticlesPage(state, articles_page){ 
			state.articles_page = articles_page; 
		},	
		nullableTypeClassProduct(state, key){ 
			state.articles[key].type_product.id = null;
			state.articles[key].class_product.id = null;  
		},
		setSelectCopy(state, data){ 
			state.copy.warehouse.id = data.id;
			state.copy.warehouse.name = data.name;
			state.copy.obj = data.obj; 	 
		},
		setCopyNotify(state, data){ 
			state.copy.notify.status = data.status;
			state.copy.notify.text = data.text; 
		}
	},
	actions: { 
		async loadFilter ({ commit, rootGetters }){  
			await api.getFilter({ account_id:rootGetters['account/account'].user_id }).then((response)=>{
				if(response.result) {
					commit('setFilter', response.filter);	  
				}		
			});	   	 
		},
		async saveFilter({ state, rootGetters }, data){  
			await api.setFilter({ 
				account_id:rootGetters['account/account'].user_id, 
				warehouse_id:state.filter.warehouse_id, 
				params:data.params 
			}).then((response)=>{
				// if(response.result) {
					 
				// }		
			});	   	 
		},
		async loadCategoryProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"category_product", status:false });	  
			await api.getCategoryProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) {
					commit('setCategoryProduct', response.category_product);	 
					commit('setLoadStatus', { type:"category_product", status:true });	 
				}		
			});	   	 
		},
		async upCategoryProduct ({ state, dispatch }, data){   
			await api.upCategoryProduct({
				action:data.action,
				id:data.id,
				warehouse_id:state.filter.warehouse_id,
				name:data.name,
			}).then((response)=>{
				if(response.result) {
					dispatch('loadCategoryProduct');	  
				}		
			});	   	 
		},      
		async upTypeClassProduct ({ state, dispatch }, data){   

			let method = null;
 
			if(state.type_class.obj_name == "type_product")
				method = "upTypeProduct";

			if(state.type_class.obj_name == "class_product")
				method = "upClassProduct";
			
			if(method !== null)
				await api[method]({
					action:data.action,
					id:data.id,
					name:data.name,
					category_id:state.category_product[state.type_class.category_key].id
				}).then((response)=>{
					if(response.result) {
						dispatch('loadCategoryProduct');	  
					}		
				});  	 
		},   
		async loadColourProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"colour_product", status:false });	  
			await api.getColourProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) {
					commit('setColorProduct', response.colour_product);	 
					commit('setLoadStatus', { type:"colour_product", status:true });	 
				}		
			});	   	 
		},
		async upColourProduct ({ state, dispatch }, data){    
			await api.upColourProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name, 
				colour_code:data.colour_code, 
			}).then((response)=>{
				if(response.result) {
					dispatch('loadColourProduct');	  
				}		
			});	   	 
		},	  
		async loadSupplierProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"supplier_product", status:false });	  
			await api.getSupplierProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setSupplierProduct', response.supplier_product);	 
					commit('setLoadStatus', { type:"supplier_product", status:true });	 
				}		
			});	   	 
		},
		async upSupplierProduct ({ state, dispatch }, data){    
			await api.upSupplierProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadSupplierProduct');	  
				}		
			});	   	 
		},	 
		async loadArticleSupplierProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"article_supplier_product", status:false });	  
			await api.getArticleSupplierProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setArticleSupplierProduct', response.article_supplier_product);	 
					commit('setLoadStatus', { type:"article_supplier_product", status:true });	 
				}		
			});	   	 
		},
		async upArticleSupplierProduct ({ state, dispatch }, data){    
			await api.upArticleSupplierProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadArticleSupplierProduct');	  
				}		
			});	   	 
		},	  
		async loadPayerProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"payer_product", status:false });	  
			await api.getPayerProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setPayerProduct', response.payer_product);	 
					commit('setLoadStatus', { type:"payer_product", status:true });	 
				}		
			});	   	 
		},
		async upPayerProduct ({ state, dispatch }, data){    
			await api.upPayerProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadPayerProduct');	  
				}		
			});	   	 
		}, 
		async loadNamesProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"names_product", status:false });	  
			await api.getNamesProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setNamesProduct', response.names_product);	 
					commit('setLoadStatus', { type:"names_product", status:true });	 
				}		
			});	   	 
		},
		async upNamesProduct ({ state, dispatch }, data){    
			await api.upNamesProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadNamesProduct');	  
				}		
			});	   	 
		}, 		
		async loadUnitsProduct ({ state, commit }){  
			commit('setLoadStatus', { type:"units_product", status:false });	  
			await api.getUnitsProduct({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setUnitsProduct', response.units_product);	 
					commit('setLoadStatus', { type:"units_product", status:true });	 
				}		
			});	   	 
		},
		async upUnitsProduct ({ state, dispatch }, data){    
			await api.upUnitsProduct({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadUnitsProduct');	  
				}		
			});	   	 
		}, 
		async loadCategoryWriteOffs ({ state, commit }){  
			commit('setLoadStatus', { type:"category_write_offs", status:false });	  
			await api.getCategoryWriteOffs({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setCategoryWriteOffs', response.category_write_offs);	 
					commit('setLoadStatus', { type:"category_write_offs", status:true });	 
				}		
			});	   	 
		},
		async upCategoryWriteOffs ({ state, dispatch }, data){    
			await api.upCategoryWriteOffs({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadCategoryWriteOffs');	  
				}		
			});	   	 
		},
		async loadZones ({ state, commit }){  
			commit('setLoadStatus', { type:"zones", status:false });	  
			await api.getZones({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setZones', response.zones);	 
					commit('setLoadStatus', { type:"zones", status:true });	 
				}		
			});	   	 
		},
		async upZones ({ state, dispatch }, data){    
			await api.upZones({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadZones');	  
				}		
			});	   	 
		},	  
		async loadArticles ({ state, commit, dispatch }){  		  
			commit('setLoadStatus', { type:"articles", status:false });	  
			await api.getArticles({ warehouse_id:state.filter.warehouse_id, articles_page:state.articles_page, articles_row:state.articles_row  }).then((response)=>{
				if(response.result) { 
					commit('setArticles', response.articles);	 
					dispatch("loadCategoryProduct");
					dispatch("loadColourProduct");
					dispatch("loadNamesProduct");
					commit('setLoadStatus', { type:"articles", status:true });	 
				}		
			});	   	 
		},
		async upArticles ({ state, dispatch }, data){    
			await api.upArticles({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
				names_id:data.names_id, 
				category_id:data.category_id, 
				type_id:data.type_id, 
				class_id:data.class_id, 
				colour_id:data.colour_id
			}).then((response)=>{
				if(response.result) {
					dispatch('loadArticles');	
					dispatch('loadMinRemain');	    
				}		
			});	   	 
		},  
		async endDragArticle ({ state }){    

			let up_sort = []; 
			for(let items of state.articles)
				up_sort.push(items.id)

			if(up_sort.length > 0)
				await api.upSortArticles({ 
					warehouse_id:state.filter.warehouse_id,
					up_sort 
				})   	 
		},
		async handleImgArticle ({ state, dispatch }, key){    
			 
			let formData = new FormData(); 
			formData.append('warehouse_id', state.filter.warehouse_id);
      		formData.append('file', state.articles[key].photo.file[0]);
			formData.append('id', state.articles[key].id);

			await api.uploadImgArticles(formData).then((response)=>{
				if(response.result) {
					dispatch('loadArticles');	  
				}		
			});	  	 
		},
		async delImgArticle ({ state, dispatch }, key){    
			await api.delImgArticles({id:state.articles[key].id}).then((response)=>{
				if(response.result) {
					dispatch('loadArticles');	  
				}		
			});	  	 
		},  
		async loadMinRemain ({ state, commit }){  		  
			commit('setLoadStatus', { type:"min_remain", status:false });	  
			await api.getMinRemain({ warehouse_id:state.filter.warehouse_id }).then((response)=>{
				if(response.result) { 
					commit('setMinRemain', response.min_remain);	 
					commit('setArticlesList', response.articles_list);	  
					commit('setLoadStatus', { type:"min_remain", status:true });	 
				}		
			});	   	 
		},
		async upMinRemain ({ state, dispatch }, data){    
			await api.upMinRemain({ 
				warehouse_id:state.filter.warehouse_id,
				action:data.action,
				id:data.id,
				name:data.name,  
				articles:data.articles, 
				min_remain:data.min_remain,  
			}).then((response)=>{
				if(response.result) {
					dispatch('loadMinRemain');	  
				}		
			});	   	 
		}, 
		async endDragMinRemain({ state }){     
			let up_sort = []; 
			for(let items of state.min_remain)
				up_sort.push(items.id)

			if(up_sort.length > 0)
				await api.upSortMinRemain({ 
					warehouse_id:state.filter.warehouse_id,
					up_sort 
				})   	 
		},
		async showDelete ({ state, commit }, data){   
		  
			let id, name, message = null;

			if(data.obj_name == "category_product"){
				id = state.category_product[data.key].id;
				name = state.category_product[data.key].name;
			}
			if(data.obj_name == "type_product"){
				id = state.category_product[data.key][data.obj_name][data.k].id;
				name = state.category_product[data.key][data.obj_name][data.k].name;
			}
			if(data.obj_name == "class_product"){
				id = state.category_product[data.key][data.obj_name][data.k].id;
				name = state.category_product[data.key][data.obj_name][data.k].name;
			} 
			if(data.obj_name == "colour_product"){
				id = state.colour_product[data.key].id;
				name = state.colour_product[data.key].name;
			} 
			if(data.obj_name == "names_product"){
				id = state.names_product[data.key].id;
				name = state.names_product[data.key].name;
			} 
			if(data.obj_name == "supplier_product"){
				id = state.supplier_product[data.key].id;
				name = state.supplier_product[data.key].name;
			} 
			if(data.obj_name == "article_supplier_product"){
				id = state.article_supplier_product[data.key].id;
				name = state.article_supplier_product[data.key].name;
			}
			if(data.obj_name == "payer_product"){
				id = state.payer_product[data.key].id;
				name = state.payer_product[data.key].name;
			}
			if(data.obj_name == "units_product"){
				id = state.units_product[data.key].id;
				name = state.units_product[data.key].name;
			} 
			if(data.obj_name == "category_write_offs"){
				id = state.category_write_offs[data.key].id;
				name = state.category_write_offs[data.key].name;
			}  
			if(data.obj_name == "zones"){
				id = state.zones[data.key].id;
				name = state.zones[data.key].name;
			}  
			if(data.obj_name == "articles"){
				id = state.articles[data.key].id;
				name = state.articles[data.key].name;
			}  
			if(data.obj_name == "min_remain"){
				id = state.min_remain[data.key].id;
				name = state.min_remain[data.key].name;
			}    

			//await api.valid({
 
			commit('setDeleteObj', { status:true, id, name, obj_name:data.obj_name, message });	  
		}, 
		async confirmDelete ({ state, commit, dispatch }){   

			let method = null

			if(state.delete_obj.obj_name == "category_product")
				method = "delCategoryProduct";

			if(state.delete_obj.obj_name == "type_product")
				method = "delTypeProduct";

			if(state.delete_obj.obj_name == "class_product")
				method = "delClassProduct";

			if(state.delete_obj.obj_name == "colour_product")
				method = "delColourProduct";

			if(state.delete_obj.obj_name == "names_product")
				method = "delNamesProduct";

			if(state.delete_obj.obj_name == "supplier_product")
				method = "delSupplierProduct";

			if(state.delete_obj.obj_name == "article_supplier_product")
				method = "delArticleSupplierProduct";

			if(state.delete_obj.obj_name == "payer_product")
				method = "delPayerProduct";

			if(state.delete_obj.obj_name == "units_product")
				method = "delUnitsProduct";

			if(state.delete_obj.obj_name == "category_write_offs")
				method = "delCategoryWriteOffs";

			if(state.delete_obj.obj_name == "zones")
				method = "delZones";

			if(state.delete_obj.obj_name == "articles")
				method = "delArticles";		

			if(state.delete_obj.obj_name == "min_remain")
				method = "delMinRemain";			 
  
			if(method !== null)
				await api[method]({ 
					id:state.delete_obj.id, 
				}).then((response)=>{
					if(response.result){		

						if(state.delete_obj.obj_name == "category_product" || state.delete_obj.obj_name == "type_product" || state.delete_obj.obj_name == "class_product")
							dispatch('loadCategoryProduct');	
						
						if(state.delete_obj.obj_name == "colour_product")
							dispatch('loadColourProduct');	

						if(state.delete_obj.obj_name == "names_product")
							dispatch('loadNamesProduct');	

						if(state.delete_obj.obj_name == "supplier_product")
							dispatch('loadSupplierProduct');	

						if(state.delete_obj.obj_name == "article_supplier_product")
							dispatch('loadArticleSupplierProduct');	
 
						if(state.delete_obj.obj_name == "payer_product")
							dispatch('loadPayerProduct');	

						if(state.delete_obj.obj_name == "units_product")
							dispatch('loadUnitsProduct');	
 
						if(state.delete_obj.obj_name == "category_write_offs")
							dispatch('loadCategoryWriteOffs');	

						if(state.delete_obj.obj_name == "zones")
							dispatch('loadZones');	

						if(state.delete_obj.obj_name == "articles")
							dispatch('loadArticles');	

						if(state.delete_obj.obj_name == "min_remain")
							dispatch('loadMinRemain');	
 
						commit('setDeleteObj', { status:false, id:null, name:null, obj_name:null, message:null }); 
					}		
				});	   	 
		},
		copyCheck({ state, commit, dispatch }){   
			if(state.copy.warehouse.id == state.filter.warehouse_id)		 
				dispatch("snackBar/showSnackBar", "Вы не можете скопировать данные сами в себя", {root:true});
			else
				commit("setCopyNotify", {status:true, text:"При копировании удалятся текущие данные и загрузятся новые"})
		},
		async copy({ state, commit, dispatch }){    
			await api.copyObj({ 
				copy_warehouse_id:state.copy.warehouse.id, 
				warehouse_id:state.filter.warehouse_id, 
				obj:state.copy.obj, 
			}).then((response)=>{
				if(response.result){ 
					if(state.copy.obj == "category_product") 
						dispatch("loadCategoryProduct") 
					
					if(state.copy.obj == "colour_product") 
						dispatch("loadColourProduct") 
					
					if(state.copy.obj == "names_product") 
						dispatch("loadNamesProduct") 
 
					if(state.copy.obj == "supplier_product") 
						dispatch("loadSupplierProduct") 

					if(state.copy.obj == "article_supplier_product") 
						dispatch("loadArticleSupplierProduct") 

					if(state.copy.obj == "payer_product") 
						dispatch("loadPayerProduct") 

					if(state.copy.obj == "units_product") 
						dispatch("loadUnitsProduct") 

					if(state.copy.obj == "category_write_offs") 
						dispatch("loadCategoryWriteOffs") 

					if(state.copy.obj == "zones") 
						dispatch("loadZones") 

					if(state.copy.obj == "articles") 
						dispatch("loadArticles") 
   
					commit("setCopyNotify", { status:false, text:null });
					commit("setSelectCopy", { id:null, name:null, obj:null }); 
				}
			}) 
		}
	}, 
} 