import * as api from '@/api/warehouse_sale_entry.js';

export default {
	namespaced: true,
	state: {
		status:false,
		type:null,
		entry_id:null,
		entry:null,
		entry_head_status_load:false,

		category_list:[],
		supplier_list:[],
		payer_list:[],
	},
	getters: {
		warehouse_sale_entry_card:state=>{
			return state
		},  
	},
	mutations: { 
		showEntryCard(state, data){ 
			state.status = data.status; 
			state.type = data.type; 
			state.entry_id = data.entry_id; 
		},  
		setList(state, data){
			state.category_list = data.category_list; 
			state.supplier_list = data.supplier_list; 
			state.payer_list = data.payer_list; 
		},
		setEntryHead(state, entry){   
			state.entry = entry;   
		},
		setEntryHeadStatusLoad(state, status){   
			state.entry_head_status_load = status;   
		},
		 
	},
	actions: { 
		async showEntryCard ({ state, commit, rootGetters, dispatch }, data){  
			
			commit('showEntryCard', data);
			commit('setEntryHeadStatusLoad', false);

			await api.getHeadEntry({ 
				warehouse_id:rootGetters['warehouse_sale_entry/warehouse_sale_entry'].filter.warehouse_id,	
				entry_id:state.entry_id,			 
			}).then((response)=>{
				if(response.result) 
					commit('setEntryHead', response.entry);		
					commit('setList', { category_list:response.category_list, supplier_list:response.supplier_list, payer_list:response.payer_list });		 
					commit('setEntryHeadStatusLoad', true);		


			});	   	 
		}, 
	}, 
} 