import * as api from '@/api/warehouse_sale_entry.js';

export default {
	namespaced: true,
	state: {
		filter:{
			warehouse_id:null,
			period:null,
			date:null,
			entry_id:null,
			position_id:null,
			category_id:null,
			type_id:null,
			class_id:null,
			colour_id:null,
			supplier_id:null,
			payer_id:null,
			conducted:"show",
			category_list:[],
			colour_list:[],
			supplier_list:[],
			payer_list:[],	  
		},
		row:20,
		page:1,
		quantity_page:0,
		table_status_load:false,
		table:[],
		order_by:{
			date:'desc',
			entry_id:null, 
		}, 
		timeout:null,
	},
	getters: {
		warehouse_sale_entry:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, data){ 
			state.filter = data.filter; 
			state.category_list = data.list.category_list; 
			state.colour_list = data.list.colour_list; 
			state.supplier_list = data.list.supplier_list; 
			state.payer_list = data.list.payer_list; 
		},  
		setFilterParams(state, data){ 
			state.filter[data.param] = data.value; 
		},  
		nullableFilter(state){
			state.filter.period = null;
			state.filter.date = null;
			state.filter.entry_id = null;
			state.filter.position_id = null;
			state.filter.category_id = null;
			state.filter.type_id = null;
			state.filter.class_id = null;
			state.filter.colour_id = null;
			state.filter.supplier_id = null;
			state.filter.payer_id = null;
			state.filter.conducted = "show";
		},
		setRow(state, row){
			state.row = row
		},
		setEntry(state, data){
			state.quantity_page = data.quantity_page
			state.table = data.table
		},
		setTableStatusLoad(state, status){
			state.table_status_load = status
		},
		setOrderBy(state, param){ 			  
			if(param == "date"){  
				state.order_by.date = state.order_by.date == null ? 'desc' : state.order_by.date == 'desc' ? 'asc' : 'desc'; 
				state.order_by.entry_id = null 	 
			}
			if(param == "entry_id"){  
				state.order_by.entry_id = state.order_by.entry_id == null ? 'desc' : state.order_by.entry_id == 'desc' ? 'asc' : 'desc'; 
				state.order_by.date = null 	 
			}
		},
	},
	actions: { 
		async loadFilter ({ commit, rootGetters, dispatch }){  
			await api.getFilter({ account_id:rootGetters['account/account'].user_id }).then((response)=>{
				if(response.result) {
					commit('setFilter', { filter:response.filter, list:response.list });	  
					dispatch("loadEntryAll");
				}		
			});	   	 
		},
		async saveFilter({ state, rootGetters, dispatch }){
			let response = await api.setFilter({ 
				account_id:rootGetters['account/account'].user_id, 
				warehouse_id:state.filter.warehouse_id, 
				period:state.filter.period, 
				date:state.filter.date,
				entry_id:state.filter.entry_id,
				position_id:state.filter.position_id,
				category_id:state.filter.category_id,
				type_id:state.filter.type_id,
				class_id:state.filter.class_id,
				colour_id:state.filter.colour_id,
				supplier_id:state.filter.supplier_id,
				payer_id:state.filter.payer_id,
				conducted:state.filter.conducted,
			})  	
			if(response.result)  
				dispatch("loadEntryAll");
			 	 
			return response;
		},  
		async loadEntryAll({ state, commit }){  
			commit("setTableStatusLoad", false);
			await api.getEntryAll({
				warehouse_id:state.filter.warehouse_id, 
				period:state.filter.period, 
				date:state.filter.date,
				entry_id:state.filter.entry_id,
				position_id:state.filter.position_id,
				category_id:state.filter.category_id,
				type_id:state.filter.type_id,
				class_id:state.filter.class_id,
				colour_id:state.filter.colour_id,
				supplier_id:state.filter.supplier_id,
				payer_id:state.filter.payer_id,
				conducted:state.filter.conducted,
				row:state.row,
				page:state.page,
				order_by:state.order_by,
			}).then((response)=>{
				if(response.result) {
					commit('setEntry', { table:response.table, quantity_page:response.quantity_page });	  
					commit("setTableStatusLoad", true);
				}		
			});	   	 
		},
		async orderBy({ commit, dispatch }, param){  
			commit('setOrderBy', param);	
			dispatch("loadEntryAll");	 
		},  
		async upEntry({ state, dispatch }, data){   
			await api.upEntry({
				warehouse_id:state.filter.warehouse_id, 
				action:data.action,
				param:data.param,
				value:data.value,
				entry_id:data.entry_id,
			}).then((response)=>{
				if(response.result) {
					dispatch("loadEntryAll");
				}
			}) 
		},
		changeDataEntry({ state }, data){	  
			let value = null;
			let delay = 1500;
			if(data.param == "comment") value = state.table[data.key].comment; 
			clearTimeout(state.timeout) 
			state.timeout = setTimeout(async () => {   
				await api.upEntry({  
					warehouse_id:state.filter.warehouse_id, 
					action:"up", 
					param:data.param, 
					value, 
					entry_id:state.table[data.key].entry_id
				});	  
			}, delay); 
		},  
	}, 
} 