import * as api from '@/api/warehouse_sale_list.js';

export default {
	namespaced: true,
	state: {
		warehouse:[],
		delete_warehouse:{
			status:false,
			id:null
		}
	},
	getters: {
		warehouse_sale_list:state=>{
			return state
		},  
	},
	mutations: { 
		setWarehouse(state, warehouse){ 
			state.warehouse = warehouse; 
		},   
		setEdit(state, data){ 
			state.warehouse[data.key].edit = data.status; 
		},  
		setDeleteWarehouse(state, data){ 
			state.delete_warehouse.status = data.status; 
			state.delete_warehouse.id = data.id; 
		},  
	},
	actions: { 
		async loadWarehouseSaleList ({ commit }){ 
			await api.getWarehouseSaleList().then((response)=>{
				if(response.result) {
					commit('setWarehouse', response.warehouse);	 
				}		
			});	   	 
		},     
		async createWarehouse({ dispatch }){ 
			await api.upWarehouseSaleList({ 
				action:"add", 
			}).then((response)=>{
				if(response.result) {
					dispatch('loadWarehouseSaleList');	 
				}		
			});	   	 
		},     
		async saveWarehouse ({ state, commit }, key){ 
			await api.upWarehouseSaleList({ 
				action:"up",
				id:state.warehouse[key].id, 
				name:state.warehouse[key].name, 
				address:state.warehouse[key].address, 
				view_app:state.warehouse[key].view_app, 
			}).then((response)=>{
				if(response.result) {
					commit('setEdit', { key, status:false});	 
				}		
			});	   	 
		},     
		async delWarehouse ({ state, commit, dispatch }){  
			await api.deleteWarehouseSaleList({ id:state.delete_warehouse.id }).then((response)=>{
				if(response.result) {
					commit("setDeleteWarehouse", {status:false, id:null})
					dispatch('loadWarehouseSaleList');	 
				}		
			});	   	 
		},     
	}, 
} 