import server from '@/api/http_texstage';

export async function getFilter(params){  
	let { data } = await server.get('warehouse_sale/input/filter/get', { params });
	return data;
}   
export async function setFilter(params){  
	let { data } = await server.get('warehouse_sale/input/filter/set', { params });
	return data;
}   
export async function getCategoryProduct(params){  
	let { data } = await server.get('warehouse_sale/input/category_product/get', { params });
	return data;
}   
export async function upCategoryProduct(params){  
	let { data } = await server.get('warehouse_sale/input/category_product/up', { params });
	return data;
}   
export async function delCategoryProduct(params){  
	let { data } = await server.get('warehouse_sale/input/category_product/delete', { params });
	return data;
} 
export async function upTypeProduct(params){  
	let { data } = await server.get('warehouse_sale/input/type_product/up', { params });
	return data;
}   
export async function delTypeProduct(params){  
	let { data } = await server.get('warehouse_sale/input/type_product/delete', { params });
	return data;
} 
export async function upClassProduct(params){  
	let { data } = await server.get('warehouse_sale/input/class_product/up', { params });
	return data;
}   
export async function delClassProduct(params){  
	let { data } = await server.get('warehouse_sale/input/class_product/delete', { params });
	return data;
} 
export async function getColourProduct(params){  
	let { data } = await server.get('warehouse_sale/input/color_product/get', { params });
	return data;
}
export async function upColourProduct(params){  
	let { data } = await server.get('warehouse_sale/input/color_product/up', { params });
	return data;
}
export async function delColourProduct(params){  
	let { data } = await server.get('warehouse_sale/input/color_product/delete', { params });
	return data;
} 
export async function getNamesProduct(params){  
	let { data } = await server.get('warehouse_sale/input/names_product/get', { params });
	return data;
}
export async function upNamesProduct(params){  
	let { data } = await server.get('warehouse_sale/input/names_product/up', { params });
	return data;
}
export async function delNamesProduct(params){  
	let { data } = await server.get('warehouse_sale/input/names_product/delete', { params });
	return data;
}
export async function getSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/supplier_product/get', { params });
	return data;
}
export async function upSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/supplier_product/up', { params });
	return data;
}
export async function delSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/supplier_product/delete', { params });
	return data;
}
export async function getArticleSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/article_supplier_product/get', { params });
	return data;
}
export async function upArticleSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/article_supplier_product/up', { params });
	return data;
}
export async function delArticleSupplierProduct(params){  
	let { data } = await server.get('warehouse_sale/input/article_supplier_product/delete', { params });
	return data;
}
export async function getPayerProduct(params){  
	let { data } = await server.get('warehouse_sale/input/payer_product/get', { params });
	return data;
}
export async function upPayerProduct(params){  
	let { data } = await server.get('warehouse_sale/input/payer_product/up', { params });
	return data;
}
export async function delPayerProduct(params){  
	let { data } = await server.get('warehouse_sale/input/payer_product/delete', { params });
	return data;
}
export async function getUnitsProduct(params){  
	let { data } = await server.get('warehouse_sale/input/units_product/get', { params });
	return data;
}
export async function upUnitsProduct(params){  
	let { data } = await server.get('warehouse_sale/input/units_product/up', { params });
	return data;
}
export async function delUnitsProduct(params){  
	let { data } = await server.get('warehouse_sale/input/units_product/delete', { params });
	return data;
}
export async function getCategoryWriteOffs(params){  
	let { data } = await server.get('warehouse_sale/input/category_write_offs/get', { params });
	return data;
}
export async function upCategoryWriteOffs(params){  
	let { data } = await server.get('warehouse_sale/input/category_write_offs/up', { params });
	return data;
}
export async function delCategoryWriteOffs(params){  
	let { data } = await server.get('warehouse_sale/input/category_write_offs/delete', { params });
	return data;
} 
export async function getZones(params){  
	let { data } = await server.get('warehouse_sale/input/zones/get', { params });
	return data;
}
export async function upZones(params){  
	let { data } = await server.get('warehouse_sale/input/zones/up', { params });
	return data;
}
export async function delZones(params){  
	let { data } = await server.get('warehouse_sale/input/zones/delete', { params });
	return data;
}
export async function getArticles(params){  
	let { data } = await server.get('warehouse_sale/input/articles/get', { params });
	return data;
}
export async function upArticles(params){  
	let { data } = await server.get('warehouse_sale/input/articles/up', { params });
	return data;
}
export async function delArticles(params){  
	let { data } = await server.get('warehouse_sale/input/articles/delete', { params });
	return data;
}   
export async function uploadImgArticles(params){  
	let { data } = await server.post('warehouse_sale/input/articles/img/upload', params);
	return data;
} 
export async function delImgArticles(params){  
	let { data } = await server.get('warehouse_sale/input/articles/img/delete', { params });
	return data;
} 
export async function upSortArticles(params){  
	let { data } = await server.get('warehouse_sale/input/articles/sort/up', { params });
	return data;
} 
export async function getMinRemain(params){  
	let { data } = await server.get('warehouse_sale/input/min_remain/get', { params });
	return data;
}
export async function upMinRemain(params){  
	let { data } = await server.get('warehouse_sale/input/min_remain/up', { params });
	return data;
}
export async function upSortMinRemain(params){  
	let { data } = await server.get('warehouse_sale/input/min_remain/sort/up', { params });
	return data;
} 
export async function delMinRemain(params){  
	let { data } = await server.get('warehouse_sale/input/min_remain/delete', { params });
	return data;
}
export async function copyObj(params){  
	let { data } = await server.get('warehouse_sale/input/obj/copy', { params });
	return data;
} 